import React from "react";
import Speiseteller from "../../../static/images/wishlist/Speiseteller.jpg"
import Fruestuecksteller from "../../../static/images/wishlist/Fruehstuecksteller.jpg"
import Suppenteller from "../../../static/images/wishlist/Suppenteller.jpg"
import Platte from "../../../static/images/wishlist/Platteoval.jpg"
import Schuessel from "../../../static/images/wishlist/Schuessel.jpeg"
import BecherMitHenkel from "../../../static/images/wishlist/BecherMitHenkel.jpg"
import Schaelchen from "../../../static/images/wishlist/Schaelchen.jpg"
import Eierbecher from "../../../static/images/wishlist/Eierbecher.jpg"
import FrenchBowl from "../../../static/images/wishlist/French-Bol.jpg"
import Mokkaobertasse from "../../../static/images/wishlist/Mokka-Tasse.jpg"
import Mokkauntertasse from "../../../static/images/wishlist/Mokka-Untertasse.jpg"
import Fruehstueckstasse from "../../../static/images/wishlist/Frueckstueckstasse.jpeg"
import Fruehstuecksuntertasse from "../../../static/images/wishlist/Fruehstuecksuntertasse.jpeg"
import Sauciere from "../../../static/images/wishlist/Sauciere.jpg"
import Milchkaennchen from "../../../static/images/wishlist/Milchkaennchen.jpeg"
import Zuckerdose from "../../../static/images/wishlist/Zuckerdose.jpeg"
import Besteck from "../../../static/images/wishlist/Besteck_Boston.png"
import Dyson from "../../../static/images/wishlist/Dyson_Airwrap.jpg"
import Glasglocke from "../../../static/images/wishlist/Glasglocke.jpg"
import Thermometer from "../../../static/images/wishlist/Thermometer.jpeg"
import Steakbesteck from "../../../static/images/wishlist/Steakbesteck.png"
import Topf_Ela from "../../../static/images/wishlist/Topf_Ela.jpg"
import Salz from "../../../static/images/wishlist/Salzmuehle.png"
import Pfeffer from "../../../static/images/wishlist/Pfeffermuehle.png"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
function Wishlist() {
    return (
        <div>
            <h1 className="md:text-8xl text-4xl text-primary_dark font-semibold font-sawarabi-mincho m-2">Wunschliste</h1>
            <div className="py-8">
                <h2 className="md:text-3xl text-2xl text-primary_dark font-semibold font-sawarabi-mincho m-2">For Me Geschirrset</h2>
            </div>
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className=" overflow-x-auto">
                        <table className="border-collapse table-auto w-full text-sm">
                            <thead>
                                <tr >
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Artikel-Nr.</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400  w-auto">Produktbild</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Bezeichnung</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Preis im Laden</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Gewünschte Anzahl</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2620</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Speiseteller} alt="Speiseteller"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Speiseteller</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">11,80</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">8</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2640</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Fruestuecksteller} alt="Frühstücksteller"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Frühstücksteller</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">9,70</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">8</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2700</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Suppenteller} alt="Suppenteller"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Suppenteller</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">11,80</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">8</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2940</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Platte} alt="Platte oval"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Platte oval</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">27,90</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">3140</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Schuessel} alt="Schüssel"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Schüssel</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">33,50</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">9651</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={BecherMitHenkel} alt="Becher mit Henkel"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Becher mit Henkel</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">7,60</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">4</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">3810</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Schaelchen} alt="Schälchen"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Schälchen</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">9,70</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">4</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1951</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Eierbecher} alt="Eierbecher"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Eierbecher</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">5,50</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">4</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1900</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={FrenchBowl} alt="French-Bol"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">French-Bol</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">11,80</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">4</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1420</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Mokkaobertasse} alt="Mokka-/Espressoobertasse"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Mokka-/Espressoobertasse</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">7,60</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1430</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Mokkauntertasse} alt="Mokka-/Espressountertasse"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Mokka-/Espressountertasse</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">6,30</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">2</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1240</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Fruehstueckstasse} alt="Frühstücksobertasse"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Frühstücksobertasse</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">10,40</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">6</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1250</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Fruehstuecksuntertasse} alt="Frühstücksuntertasse"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Frühstücksuntertasse</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">7,70</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">6</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">3407</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Sauciere} alt="Sauciere"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Sauciere</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">29,40</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">0780</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Milchkaennchen} alt="Milchkännchen"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Milchkännchen</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">16,00</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">0960</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Zuckerdose} alt="Zuckerdose"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Zuckerdose</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">20,90</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
            <div>
                <p className="mt-5 mb-5">Das Geschirr heißt „For me“ und ihr könnt es im Villeroy & Boch Store im LUV Center (Dänischburg) kaufen. Bitte kauft das Geschirr nur direkt im diesen Laden und gebt dabei als Kundenkonto-Inhaber Nele Rudzki an, weil erst dann kann euch gesagt werden, welche Artikel in welcher Stückzahl schon gekauft wurden. Ihr könnt auch gerne vorher Nele kontaktieren und sie kann euch dann sagen, was schon gekauft worden ist. Bis zum 31.03.22 würdet auch noch 20% im Laden auf das Geschirr bekommen.</p>
            </div>
            <div className="py-8">
                <h2 className="md:text-3xl text-2xl text-primary_dark font-semibold font-sawarabi-mincho m-2">Weitere Wünsche</h2>
            </div>
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className=" overflow-x-auto">
                        <table className="border-collapse table-auto w-full text-sm">
                            <thead>
                                <tr >
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Bezeichnung</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400  w-auto">Produktbild</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Marke/Hersteller</th>
                                    <th className="border-b font-medium p-3 pl-8 pt-0 pb-3 text-slate-400 ">Preis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Boston Besteck-Set 66-teilig, 12 Personen</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Besteck} alt="Boston Besteck-Set 66-teilig, 12 Personen"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">WMF</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">449,00</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Meater Wireless Smart Thermometer for Meat</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Thermometer} alt="Meater Wireless Smart Thermometer for Meat"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Meater</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">119,00</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Sally Gusseisentopf Ela</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Topf_Ela} alt="Sally Gusseisentopf Ela"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Sally´s Shop</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">149,90</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Pfeffermühle aus Buchenholz</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Pfeffer} alt="Pfeffermühle aus Buchenholz"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Le Creuset</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">40,00</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Salzmühle aus Buchenholz</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Salz} alt="Salzmühle aus Buchenholz"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Le Creuset</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">40,00</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Steakbesteck-Set 12-teilig</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Steakbesteck} alt="Steakbesteck-Set 12-teilig"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">WMF</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">54,99</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Ib Laursen Glasglocke einfach</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Glasglocke} alt="Ib Laursen Glasglocke einfach"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Ib Laursen</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">43,80</td>
                                </tr>
                                <tr>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Dyson Airwrap Complete Long Haarstyler</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 "><img className="h-24 w-auto object-contain cursor-pointer mx-auto" src={Dyson} alt="Dyson Airwrap Complete Long Haarstyler"></img></td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">Dyson</td>
                                    <td className="border-b border-slate-100 p-3 pl-8 text-slate-500 ">499,00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
            <div>
                <p className="mt-5 mb-5">
                    Wenn ihr euch etwas von dieser Liste ausgesucht haben solltet, kontaktiert bitte Nele, damit sie euch sagen kann, ob der Artikel noch frei ist und wo ihr diesen kaufen könnt.<br></br>
                    Sollte auf den beiden Listen nichts dabei sein, was euch als Geschenk gefällt, würden wir uns auch über eine finanzielle Unterstützung für unsere Flitterwochen freuen.<br></br>
                    <div>&nbsp;</div>
                    <p className="text-lg">Kontakt:</p><br></br>
                    Nele Rudzki<br></br>
                    015770451803

                </p>
            </div>
        </div>
    )
}
export default Wishlist