import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { isAuthenticated } from "./utils/auth"
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isAuthenticated() && location.pathname !== `/guests/login`) {
    navigate("/guests/login")
    return null
  }
  return <Component {...rest} />
}
PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    location: PropTypes.any,
  }
export default PrivateRoute