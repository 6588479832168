import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function Countdown() {
  const calculateTimeLeft = () => {
    let year = Date.parse('26 Aug 2022 11:40:00 GMT')
    const difference = year - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Tage: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Stunden: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minuten: Math.floor((difference / 1000 / 60) % 60),
        Sekunden: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
    <div className="md:text-3xl text-2xl text-primary_dark font-semibold font-sawarabi-mincho m-2">
      {timerComponents.length ? timerComponents : <span>Ende</span>}
    </div>
  );
}
Countdown.propTypes={
  weddingdate: PropTypes.string
}
export default Countdown
