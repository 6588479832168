import React from "react"
import { login, isAuthenticated} from "./utils/auth"

const AuthLogin = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  return (
    <>
      <p>Anmelden success</p>
    </>
  )
}

export default AuthLogin