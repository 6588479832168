import React from "react";

function Locations() {
    return (
        <div>
            <h1 className="md:text-8xl text-4xl font-semibold text-primary_dark font-sawarabi-mincho m-2">Locations</h1>
            <h2 className="md:text-3xl text-2xl text-primary_dark font-semibold font-sawarabi-mincho m-2">Restaurant Hauck</h2>
            <p className="mt-5 mb-5">
                Das Restaurant Hauck liegt unmittelbar an der Trave vor den Toren Lübecks. In dem Restaurant haben bereits Lea´s Eltern ihr Hochzeit gefeiert und auch die Konfirmationen von Lea und ihrer Schwester Nele wurden hier gefeiert. Die Anfahrt erfolgt mit dem Auto oder Taxi und es sind Parkplätze vorhanden. Die Freie Trauung findet im Garten des Restaurants statt und ihr könnt einfach über den Parkplatz direkt in den Garten gehen. <br></br>
                Restaurant Hauck <br></br>
                Stormarnstr. 14<br></br>
                23619 Hamberge
            </p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2789.948621959326!2d10.589461389960878!3d53.83925062282373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b20e55d38aa6dd%3A0x58f8733ab60b5d87!2sRestaurant%20Hauck!5e0!3m2!1sen!2sde!4v1655053531081!5m2!1sen!2sde" className="w-full h-96"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            />
            <h2 className="md:text-3xl text-2xl text-primary_dark font-semibold font-sawarabi-mincho m-2">Lindesche Villa</h2>
            <p className="mt-5 mb-5">
                Die Lindesche Villa liegt direkt am Rande der Lübecker Innenstadt und dient als Dienstgebäude des Lübecker Standesamtes. Dieses klassizistische Gebäude wurde 1804 von dem dänischen Architekten Joseph Christian Lillie für den Kaufmann und Senator Hermann Haartmann errichtet. Den Namen &quot;Lindesche Villa&quot; hat das Sommerhaus in der Ratzeburger Allee 16 von einem langjährigen Bewohner, dem Augenheilkundler Dr. Max Linde (1862-1940), der, von Hamburg kommend, sich hier niederließ.
            </p>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2353.315217031798!2d10.694781716122597!3d53.85504254418629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41624a0b86ed5b63%3A0x515b71e75b811f60!2sStandesamt%20L%C3%BCbeck!5e0!3m2!1sen!2sde!4v1641575912956!5m2!1sen!2sde"
                className="w-full h-96"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            />
        </div>
    )
}
export default Locations