import React from "react"
import { withPrefix } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import SEO from "./seo"
import Startsection from "./sections/startsection"
import Wishlist from "./sections/wishlist"
import Imagegrid from "./sections/imagegrid"
import Locations from "./sections/locations"

const Start = () => (
  <div>
    <SEO
      keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      title="Start"
    />
    <section id="Start" className="text-center md:m-8 mt-8 mb-8">
      <Startsection></Startsection>
    </section>
    <hr className="mx-8"></hr>
    <section className="md:m-8 mt-8 mb-8">
      <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 max-w-6xl">
        <button onClick={() => scrollTo('#Start')}>
          <div className="flex flex-col bg-gray-200 rounded-lg p-4 md:m-2 my-2 ml-12 mr-12 transform hover:scale-110 transition duration-300">
            <div className="h-40 bg-gray-400 rounded-lg bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${withPrefix('images/standesamt.jpg')})` }}></div>
            <div className="flex flex-col items-start mt-4">
              <h4 className="text-3xl text-primary_dark font-thin font-sawarabi-mincho">Start</h4>
            </div>
          </div>
        </button>
        <button onClick={() => scrollTo('#Location')}>
          <div className="flex flex-col bg-gray-200 rounded-lg p-4 md:m-2 my-2 ml-12 mr-12 transform hover:scale-110 transition duration-300">
            <div className="h-40 bg-gray-400 rounded-lg bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${withPrefix('images/location.jpeg')})` }}></div>
            <div className="flex flex-col items-start mt-4">
              <h4 className="text-3xl text-primary_dark font-thin font-sawarabi-mincho">Locations</h4>
            </div>
          </div>
        </button>
        <button onClick={() => scrollTo('#Dresscode')}>
          <div className="flex flex-col bg-gray-200 rounded-lg p-4 md:m-2 my-2 ml-12 mr-12 transform hover:scale-110 transition duration-300">
            <div className="h-40 bg-gray-400 rounded-lg bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${withPrefix('images/clothes.jpg')})` }}></div>
            <div className="flex flex-col items-start mt-4">
              <h4 className="text-3xl text-primary_dark font-thin font-sawarabi-mincho">Dresscode</h4>
            </div>
          </div>
        </button>
        <button onClick={() => scrollTo('#Wunschliste')}>
          <div className="flex flex-col bg-gray-200 rounded-lg p-4 md:m-2 my-2 ml-12 mr-12 transform hover:scale-110 transition duration-300">
            <div className="h-40 bg-gray-400 rounded-lg bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${withPrefix('images/gift.jpg')})` }}></div>
            <div className="flex flex-col items-start mt-4">
              <h4 className="text-3xl text-primary_dark font-thin font-sawarabi-mincho">Wunschliste</h4>
            </div>
          </div>
        </button>
      </div>
    </section>
    <hr className="mx-8"></hr>
    <section id="Location" className="text-center md:m-8 mt-8 mb-8">
      <Locations></Locations>
    </section>
    <hr className="mx-8"></hr>
    <section id="Dresscode" className="text-center md:m-8 mt-8 mb-8">
      <Imagegrid></Imagegrid>
    </section>
    <hr className="mx-8"></hr>
    <section id="Wunschliste" className="text-center md:m-8 mt-8 mb-8">
      
      <Wishlist></Wishlist>
    </section>
    <button onClick={() => scrollTo('#Start')} id="scroll-btn" className="py-2 px-4 mt-1 mb-1 ml-2 mr-2 rounded text-white bg-primary_dark float-right hover:bg-primary transition duration-300" title="Scroll to top">Back to Top</button>
  </div>
)

export default Start
