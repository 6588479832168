import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Slide1 from "../../../static/images/slide1.jpg"
import Slide2 from "../../../static/images/slide2.jpg"
import Slide3 from "../../../static/images/slide3.jpg"
import Slide4 from "../../../static/images/slide4.jpg"
import Slide5 from "../../../static/images/slide5.jpg"
import Slide6 from "../../../static/images/slide6.jpg"
import Slide7 from "../../../static/images/slide7.jpg"
import Slide8 from "../../../static/images/slide8.jpg"
import Slide9 from "../../../static/images/slide9.jpg"

function Imagegrid() {
    return (
        <div>
            <h1 className="md:text-8xl text-4xl text-primary_dark font-semibold font-sawarabi-mincho m-2">Dresscode</h1>
            <p className="mt-5 mb-5">Liebe Gäste, <br></br>
                wir feiern unsere Hochzeit im &quot;Boho-Stil&quot;. Wir würden uns daher sehr freuen, wenn ihr euch dementsprechend kleiden würdet. Konkret heißt dies für die Männer Anzug mit oder ohne Krawatte/ Fliege, gern in hellen Farben und leichten Stoffen. Für die Frauen gerne Cocktailkleider, schlichte Abendkleider, Zweiteiler, Hosenanzüge oder Röcke mit Blusen, ebenfalls aus sommerlichen Stoffen. Sommerlich gedeckte Farben sind hierbei erwünscht. Hohe Schuhe können gerne getragen werden, aber beachtet, dass ihr über eine Wiese laufen können müsst. Bitte vermeidet Outfits, die komplett weiß, creme, champagner sind, aber diese Farben können als Grundfarbe in z.B. Röcken getragen werden. Wir würden uns auch sehr freuen, wenn ihr komplett schwarze Outfits vermeidet, aber auch hier gilt, als Grundfarbe ist auch dies in Ordnung. Für Inspirationen, als auch als Hilfe stellen wir euch einige Beispiele zur Verfügung.
            </p>
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="container grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-2 mx-auto">
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide1}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide2}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide3}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide4}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide5}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide6}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide7}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide8}
                                alt="image"></img>
                        </div>
                        <div className="w-full rounded my-2 cursor-pointer md:hover:scale-105 md:duration-150">
                            <img src={Slide9}
                                alt="image"></img>
                        </div>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </div>
    )
}
export default Imagegrid