import React from "react";
import SEO from "./seo";
import { withPrefix } from 'gatsby'
const Pictures = () => (
    <div>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Bilder"
      />
      <section className="text-center">
      <h1 className="md:text-8xl text-6xl text-primary font-semibold font-sawarabi-mincho m-2">Bilder</h1>
      <div className="bg-fixed bg-contain bg-no-repeat bg-center h-96 shadow-inner" style={{backgroundImage: `url(${withPrefix('images/teaser.jpg')})`}}></div>
      <h2 className="md:text-6xl text-4xl text-primary font-semibold font-sawarabi-mincho m-2">Hier werden alle Bilder zu sehen sein nach der Hochzeit</h2>
      </section>
      </div>
  )

export default Pictures
