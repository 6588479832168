import React from "react";
import Countdown from "../countdown"

function Startsection() {
    return (
        <div>
            <h1 className="md:text-8xl text-4xl text-primary_dark font-semibold font-sawarabi-mincho m-2">Willkommen auf der Seite zu unserer Hochzeit</h1>
            <div className="grid justify-items-center">
                <div className="md:w-1/3 w-full">
                    <blockquote className="pl-4 font-sawarabi-mincho font-thin md:text-xl text-lg leading-loose text-justify border-l-4 border-secondary">
                        Doubt thou the stars are fire,<br></br>
                        Doubt that the sun doth move, <br></br>
                        Doubt truth to be a liar,<br></br>
                        But never doubt I love.
                    </blockquote>

                    <cite className="block mt-4 text-xs font-bold text-right uppercase">
                        – William Shakespeare
                    </cite>
                </div>
            </div>
            <p className="mt-5 mb-5">Wir freuen uns schon sehr auf unseren großen Tag und können es kaum abwarten. Um euch die Wartezeit bis August zu verkürzen, haben wir euch einige wichtige und interessante Informationen zusammengestellt. Zum einen findet ihr Informationen zu unserer Location und dem Standesamt und eine Wegbeschreibung. Zum anderen haben wir euch hier unsere Geschenkwünsche übersichtlich zusammengestellt und euch auch aufgeschrieben, wo ihr welches Geschenk bekommt. Wichtige Informationen, wie den Dresscode findet ihr auch hier.
                Viel Spaß beim Erkunden und wir freuen uns sehr euch auf unserer Hochzeit begrüßen zu dürfen.<br></br>
                Lea Sophie und Morten
            </p>
            <Countdown weddingdate='26 Aug 2022 11:45:00 GMT'></Countdown>
        </div>
    )
}
export default Startsection