import React from "react";
import SEO from "./seo";

const Contact = () => (
  <div>
    <SEO
      keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      title="Kontakt"
    />
    <section>
      <div className="text-center">
        <h2 className="md:text-6xl text-4xl text-primary font-semibold font-sawarabi-mincho m-2">Für Fragen zur Hochzeit könnt ihr euch gerne melden</h2>
        <div className="py-2">
          <h2 className="md:text-3xl text-2xl text-primary font-semibold font-sawarabi-mincho m-2">Lea <a className="hover:underline" href="mailto:learudzki@gmail.com">learudzki@gmail.com</a></h2>
          <h2 className="md:text-3xl text-2xl text-primary font-semibold font-sawarabi-mincho m-2">Morten <a className="hover:underline" href="mailto:morten.luetje@gmx.de">morten.luetje@gmx.de</a></h2>
        </div>
      </div>
    </section>
  </div>
)

export default Contact;
