import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Login from "../components/login"
import Start from "../components/start"
import Pictures from "../components/pictures"
import Header from "../components/header"
import Contact from "../components/contact"
const Guests = () => (
    <Layout>
        <Router>
            <PrivateRoute path="/guests/start" component={Start} />
            <PrivateRoute path="/guests/pictures" component={Pictures} />
            <PrivateRoute path="/guests/contact" component={Contact} />
            <PrivateRoute path="/guests/header" component={Header} />
            <Login path="/guests/login" />
        </Router>
    </Layout>
)
export default Guests