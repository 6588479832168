import React from "react"
import AuthLogin from "../components/auth_login";
class Login extends React.Component {
  render() {
    return (
      <>
        <AuthLogin></AuthLogin>
      </>
    )
  }
}
export default Login